import React from 'react';
import downloadicon from '../assets/downloadicon.svg';

export const Card = ({ children }) => {
  return (
    <>
      <div className="bg-white rounded-lg p-4 card">{children}</div>
    </>
  );
};

export const Spinner = ({size = 'md', colour = 'black'}) => {
  if (size === 'lg')
      return (
          <div className="flex justify-center my-20">
              <svg className="animate-spin h-12 w-12 mr-3" viewBox="0 0 24 24">
                  <circle
                      className="opacity-0"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke={'white'}
                      strokeWidth="4"
                  />
                  <path
                      className="opacity-100"
                      fill={colour}
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
              </svg>
              Please wait while images are loading.
          </div>
      );
  else if (size === 'sm')
      return (
          <div className="flex justify-center">
              <svg className="animate-spin h-4 w-4 mr-3" viewBox="0 0 24 24">
                  <circle
                      className="opacity-0"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke={'white'}
                      strokeWidth="4"
                  ></circle>
                  <path
                      className="opacity-100"
                      fill={colour}
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
              </svg>
          </div>
      );

  return (
      <div className="flex justify-center">
          <svg className="animate-spin h-8 w-8 mr-3" viewBox="0 0 24 24">
              <circle
                  className="opacity-0"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={'white'}
                  strokeWidth="4"
              ></circle>
              <path
                  className="opacity-100"
                  fill={colour}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
          </svg>
      </div>
  );
};

export const Button = ({
  size = 'medium',
  onClick,
  children,
  radius = 'lg',
  disabled,
}) => {
  if (size === 'large')
    return (
      <button
        className={`bg-[#766AFF] hover:bg-[#544AC0] px-8 py-4 tracking-wider leading-5 rounded-${radius} text-lg text-white font-bold font-heading`}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
  else if (size === 'small')
    return (
      <button
        className={`bg-[#4F77D8] hover:bg-[#544AC0] px-4 py-2 tracking-wider leading-4 rounded-${radius} text-sm text-white font-bold font-heading`}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
  else if (size === 'outline')
    return (
      <button
        className={`border-2 border-white px-4 py-2 tracking-wider leading-4 rounded-${radius} text-sm text-white font-bold font-heading`}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
  else if (size === 'download')
    return (
      <button
        className={`border-2 bg-white text-[#23479F] hover:bg-slate-100 px-4 py-2 tracking-wider leading-4 rounded-${radius} text-sm font-bold font-heading`}
        onClick={onClick}
        disabled={disabled}
      >
        <div className="flex">
          {children}
          <img
            src={downloadicon}
            alt=""
            width={'15px'}
            height={'15px'}
            className="ml-2"
          />
        </div>
      </button>
    );
  else if (size === 'white')
    return (
      <button
        className={`border-2 bg-white text-[#23479F] hover:bg-slate-100 px-4 py-2 tracking-wider leading-4 rounded-${radius} text-sm font-bold font-heading`}
        onClick={onClick}
        disabled={disabled}
      >
        <div className="flex">{children}</div>
      </button>
    );
  else
    return (
      <button
        className={`bg-[#23479F] px-6 py-3 tracking-wider leading-5 rounded-${radius} text-base text-white font-bold font-heading`}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
};
