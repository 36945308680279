import React, { useEffect, useState, useRef } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import { useCookies } from 'react-cookie';
import { useLocation } from "react-router-dom";
import Camera from "../components/Camera";

const Layout = ({ jobid,isAuthenticated, children }) => {
    const [userDetails, setUserDetails] = useState(null);
    const [cookies] = useCookies(['jwtToken', 'email']);
    const jwtToken = cookies['jwtToken'];
    const location = useLocation(); // Get the current route
    const hideVideoPages = ['/discussion-forum', '/chat']; // List of routes where the video should not be displayed
    
    useEffect(() => {
        if (isAuthenticated) {
           const axiosInstance = axios.create({
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            });
            // Fetch user details using Axios
            axiosInstance.get("https://efhp0mxmli.execute-api.ap-south-1.amazonaws.com/dev/validate-jwt")
                .then((response) => {
                    // console.log(response.data)
                    const data = response.data.message;
                    if (data.response_code === "USER_DETAILS_RECEIVED") {
                        setUserDetails(data.user);
                    }
                })
                .catch((error) => {
                    console.error("Failed to fetch user details:", error);
                });
        }
    }, [isAuthenticated, cookies]);


    if (isAuthenticated) {
        return (
            <>
                <Navbar userDetails={userDetails} />
                <div>
                    {!hideVideoPages.includes(location.pathname) && ( // Conditionally render video
                        <>
                             <Camera jobid={jobid}></Camera>
                        </>
                    )}
                    {children}
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="flex flex-auto">
                    <div className="grow">
                        <div>{children}</div>
                    </div>
                </div>
            </>
        );
    }
};

export default Layout;
