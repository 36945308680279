import React, { useState } from 'react';
import { useQuestionContext } from '../context/QuestionContext';
import { Button } from '../components/CommonComponents';
import { submitAnswers } from '../api/serverAPI';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const Summary = ({jobId}) => {
  const { questions, answers } = useQuestionContext();
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
  const [cookies] = useCookies(['email', 'jobId']);
  const email = cookies.email;
  // const jobId = cookies.jobId;
  const navigate = useNavigate();
  console.log(jobId);

  const handleSubmit = async () => {
    // Transform the answers array into an object with indices as keys
    const answersObject = answers.reduce((acc, answer, index) => {
      acc[index] = answer;
      return acc;
    }, {});

    try {
      const response = await submitAnswers(email, jobId, answersObject);
      if (response.status === 'success') {
        setSubmissionSuccessful(true);
        navigate('/discussion-forum'); // Redirect to the thank-you page
      } else {
        alert('There was an issue submitting your answers. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting the answers:', error);
      alert('There was an error submitting your answers. Please try again later.');
    }
  };

  const renderAnswer = (question, index) => {
    switch (question.typques) {
      case 'True or False':
      case 'Multiple Choice Questions':
        const options = question.options
          .replace(/[\[\]']/g, '')
          .split(',')
          .map((option) => option.trim());
        return (
          <div key={index}>
            <ul className="list-disc list-inside">
              {options.map((option, idx) => (
                <li key={idx}>{option}</li>
              ))}
            </ul>
            <p className="text-lg font-semibold">Your Answer: {answers[index]}</p>
          </div>
        );
      default:
        return <p key={index} className="text-lg font-semibold">Your Answer: {answers[index]}</p>;
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full fixWidth">
        <h1 className="text-2xl font-bold mb-4 text-center">Summary of your interview</h1>
        {questions.map((question, index) => (
          <div key={index} className="mb-4">
            <p>
              Question {index + 1}: {question.question}
            </p>
            {renderAnswer(question, index)}
          </div>
        ))}
        <div className="flex justify-center">
          <Button size="medium" color="light-purple" type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Summary;
