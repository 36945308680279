import React, { useState } from 'react';
import { Button,Spinner } from './CommonComponents';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.svg';

const AuthForm = ({ fields, buttonText, onSubmit, title, loading }) => {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <div className="py-5">
        <img
          src={Logo}
          alt="Logo of krtrimaIQ Cognitive Solutions"
          width={300}
        />
      </div>
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-4 text-center">{title}</h2>
        <form onSubmit={handleSubmit}>
          {fields.map((field) => (
            <input
              key={field.name}
              type={field.type}
              name={field.name}
              placeholder={field.title}
              value={formData[field.name] || ''}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring focus:ring-indigo-200"
            />
          ))}
          <div className="flex justify-center">
            <Button size="medium" color="light-purple" type="submit">
              {loading ? <Spinner size="sm" colour="white" /> : buttonText}
            </Button>
          </div>
          <div
            className={`${
              buttonText === 'Login' ? 'flex' : 'hidden'
            } text-center pt-3`}
          >
            <div className="flex justify-between mx-auto">
              <Link to="/forgot-password">
                <span className="text-purple hover:text-almost-purple cursor-pointer px-2">
                  Forgot Password?
                </span>
              </Link>
              <Link to="/signup">
                <span className="text-purple hover:text-almost-purple cursor-pointer px-2">
                  Sign Up
                </span>
              </Link>
            </div>
          </div>
          <div
            className={`${
              buttonText === 'Signup' ? 'flex justify-center' : 'hidden'
            } text-center pt-3`}
          >
            <Link to="/login">
              <span className="text-purple hover:text-almost-purple cursor-pointer px-1">
                Already have an account?
              </span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthForm;
