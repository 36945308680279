import React, { useState, useEffect } from 'react';
import Logo from '../assets/logo.svg';
import { Link } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { useCookies } from 'react-cookie';

const getInitials = (firstName, lastName) => {
  // Get the first letters of first_name and last_name, make them uppercase
  const initials = (firstName[0] + lastName[0]).toUpperCase();
  return initials;
};

const Navbar = ({ userDetails }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [, , removeCookie] = useCookies(['jwtToken']);
  // console.log(userDetails)

  const logout = async () => {
    toggleDropdown(); // Close the dropdown
    removeCookie('jwtToken');
    toast.success('Logout successful');
    window.location.reload();
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const openDropdown = () => {
    setIsDropdownOpen(true);
  };

  useEffect(() => {
    // Add event listener to close the dropdown when clicking anywhere on the page
    const closeDropdownOnClickOutside = (e) => {
      if (isDropdownOpen) {
        if (!e.target.closest('.navbar-dropdown')) {
          toggleDropdown();
        }
      }
    };

    document.addEventListener('mousedown', closeDropdownOnClickOutside);

    return () => {
      document.removeEventListener('mousedown', closeDropdownOnClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className={'w-full bg-white z-40 drop-shadow-xl'}>
      <nav className={'px-[30px] py-2'}>
        <div className="flex flex-row justify-between content-center items-center">
          <Link to="/">
            <img
              src={Logo}
              alt="Logo of krtrimaIQ Cognitive Solutions"
              width={200}
              className="cursor-pointer"
            />
          </Link>
          <div className="flex items-center mx-auto justify-center">
            <div className="whitespace-nowrap text-gray-600 text-3xl font-medium">
              Interview Bot
            </div>
          </div>
          {userDetails ? (
            <div className="relative">
              <div
                className="flex justify-between content-center h-fit text-base cursor-pointer"
                id="user-info"
                onClick={openDropdown}
              >
                <div className="mx-3">
                  <div className="w-12 h-12 rounded-full bg-[#23479F] text-white text-lg flex items-center justify-center">
                    {getInitials(userDetails.first_name, userDetails.last_name)}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="font-bold">
                    {userDetails.first_name} {userDetails.last_name}
                  </div>
                  <div>{userDetails.organization}</div>
                </div>
              </div>
              {isDropdownOpen && (
                <div className="navbar-dropdown absolute top-full mt-2 right-0 w-60 bg-white border border-gray-300 shadow-lg rounded-lg">
                  <button
                    onClick={logout}
                    className="block w-full text-left px-4 py-2 hover:bg-light-blue focus:outline-none rounded-t cursor-pointer"
                  >
                    Log Out
                  </button>
                  <hr className="border-t border-gray-200" />
                </div>
              )}
            </div>
          ) : null}
        </div>
      </nav>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Navbar;
