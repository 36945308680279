import axios from 'axios';

const BASE_URL = 'https://hiring-assistant.dev2.genai.krtrimaiq.ai';

// Fetch Questions API
export const fetchQuestions = async (email, jobId) => {
  try {
    const response = await axios.post(`${BASE_URL}/interview`, { email, job_id: jobId });
    return response.data.status;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

// Verify Credentials API
export const verifyCredentials = async (email, jobId) => {
  try {
    const response = await axios.post(`${BASE_URL}/verify_credentials`, { email, job_id: jobId });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

// Submit Answers API
export const submitAnswers = async (email, jobId, answers) => {
  try {
    const payload = {
      job_id: jobId,
      email,
      answers,
    };
    const response = await axios.post(`${BASE_URL}/answers`, payload);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

