import React, { useState } from 'react';
import AuthForm from './authForm';
import { authFields } from '../contents/authentication';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useCookies } from 'react-cookie';

const Login = () => {
  const router = useNavigate();
  const [loading, setLoading] = useState(false);
  const [, setCookie] = useCookies(['jwtToken', 'email']);

  const handleLogin = async (formData) => {
    formData.application_id = 4;
    try {
      setLoading(true);
      const response = await axios.post(
        'https://efhp0mxmli.execute-api.ap-south-1.amazonaws.com/dev/login',
        formData
      );
      await toast.success('Login success');
      const jwtToken = response.data.token;
      setCookie('jwtToken', jwtToken);
      setCookie('email', formData.email); // Store email in cookies
      router('/');
    } catch (error) {
      console.log('Login failed', error.message);
      await toast.error('Login failed', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <AuthForm
        fields={authFields.login}
        buttonText="Login"
        onSubmit={handleLogin}
        title="Login"
        loading={loading}
      />
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Login;
