import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import BotIcon from '../assets/botIcon.svg';
import ListIcon from '../assets/listIcon.svg';
import { useCookies } from 'react-cookie';
import { medbotVoices } from "../components/Mapping";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faVolumeUp, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { verifyCredentials } from '../api/serverAPI';
import { Button } from '../components/CommonComponents';

const InterviewBot = ({ setjobidfun }) => {

  const [messageText, setMessageText] = useState('');
  const [showButton, setShowButton] = useState(false);
  // const [cookies] = useCookies(['voice', 'email']);
  const [cookies] = useCookies(['voice', 'jobId', 'email']);
  const [isMuted, setIsMuted] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [credentialsVerified, setCredentialsVerified] = useState(false);
  const audioRef = useRef(null);
  const email = cookies.email;
  // const jobId = cookies.job_id;
  const navigate = useNavigate();
  const recognition = useRef(null);
  const [, setCookie] = useCookies(['jobId']);
  useEffect(() => {
    if (window.SpeechRecognition || window.webkitSpeechRecognition) {
      recognition.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.current.lang = 'en-US';
      recognition.current.interimResults = false;
      recognition.current.maxAlternatives = 1;


      recognition.current.onresult = (event) => {
        const speechToText = event.results[0][0].transcript;
        console.log('Recognized Speech:', speechToText); // Log recognized speech
        setInputValue(speechToText);
      };

      recognition.current.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
      };

      recognition.current.onend = () => {
        console.log('Speech recognition ended');
        setIsRecording(false);
      };
    } else {
      console.warn('Speech Recognition API not supported.');
    }

    const message = "Hello, I'm Krtri, your interviewer.";
    const guidelines = `
      Interview Guidelines:
      Contains Fill-in-the-blanks, Multiple Choice Questions, True/False, & Subjective.
      You will be given time to answer each question based on the type of the question.
      If you still have time, you can go over the questions again.
      Please align yourself in the center of the frame and proper lighting using the camera preview.
      This is an interactive interview, you can press the mic button to speak answers and ask questions regarding the interview.
      Please enter your Job ID from the interview email, verify and press Start when you are ready.
    `;

    const combinedMessage = `${message} ${guidelines}`;
    setMessageText(message);
    speak(combinedMessage);
  }, []);

  const speak = async (text) => {
    const audio = audioRef.current;
    let voice = cookies.voice || medbotVoices[0].value;
    console.log(voice)
    const body = JSON.stringify({ voice: voice, text: text });

    try {
      const response = await fetch('https://dev.genai.krtrimaiq.ai:8000/text_to_speech', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const mediaSource = new MediaSource();
      audio.src = URL.createObjectURL(mediaSource);

      mediaSource.addEventListener('sourceopen', async () => {
        const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
        const reader = response.body.getReader();

        const pump = async () => {
          try {
            const { done, value } = await reader.read();
            if (done) {
              mediaSource.endOfStream();
              audio.play();
              audio.onended = () => {
                setShowButton(true);
              };
              return;
            }
            sourceBuffer.addEventListener('updateend', () => {
              if (!sourceBuffer.updating) {
                pump();
              }
            });
            sourceBuffer.appendBuffer(value);
          } catch (error) {
            // console.error('Error reading stream:', error);
          }
        };

        await pump();
      });

      audio.oncanplaythrough = () => {
        audio.play();
      };
    } catch (error) {
      console.error('Error fetching or playing audio:', error);
    }
  };

  const toggleMute = () => {
    const audio = audioRef.current;
    audio.muted = !audio.muted;
    setIsMuted(!isMuted);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleVerify = async () => {
    try {
      const response = await verifyCredentials(email, inputValue);

      console.log('API Response:', response);

      if (response === true) {
        setCredentialsVerified(true);
        setjobidfun(inputValue)

        // startStream();
      } else {
        alert('Entered Job ID is incorrect. Please enter the credentials provided in the email message.');
        setInputValue('');
      }
    } catch (error) {
      console.error('Error verifying credentials:', error);
      alert('An error occurred while verifying your Job ID. Please try again.');
    }
  };

  const handleSubmit = () => {
    console.log(audioRef)

    if (credentialsVerified) {
      audioRef.current.pause();
      // audioRef.current.muted=true;
      // audioRef.current.currentTime = 0;
      navigate('/question-and-answer');
      setIsMuted(false);
    } else {
      alert('Please verify your credentials first.');
    }
  };

  const toggleRecording = () => {
    if (recognition.current) {
      if (isRecording) {
        recognition.current.stop();
      } else {
        recognition.current.start();
      }
      setIsRecording(!isRecording);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className='text-center text-[#224B99] text-4xl font-semibold mt-4'>Interview Preview</div>
      <div className="flex items-center my-4 bg-white rounded-lg shadow-lg fixWidth">
        <div className="p-4 flex flex-col justify-center">
          <div className="flex items-center">
            <Button size='small'>
              <div className='flex'>
                <img
                  src={BotIcon}
                  alt="Interviewer"
                  className="mr-2"
                />
                Krtri
              </div>
            </Button>
            <p className="text-2xl font-semibold ml-2">
              <span className="text-[#224B99]">Hello, I'm Krtri, </span>
              <span className="text-[#7C7B7E]">your interviewer</span>
            </p>
          </div>
          <div className="my-4 text-gray-700">
            <h2 className="text-lg font-semibold mb-2 text-[#224B99]">Interview Guidelines</h2>
            <ul className="list-none pl-0 space-y-2">
              {[
                'Contains Fill-in-the-blanks, Multiple Choice Questions, True/False, & Subjective.',
                'You will be given time to answer each question based on the type of the question.',
                'If you still have time, you can go over the questions again.',
                'Please align yourself in the <strong>center of the frame and proper lighting using the camera preview</strong>.',
                'This is an <strong>interactive interview, you can press the mic button</strong> to speak answers and ask questions regarding the interview.',
                'Please <strong style="color: #23479F">enter your Job ID</strong> from the interview email, verify and <strong style="color: #23479F">press Start</strong> when you are ready.'
              ].map((item, index) => (
                <li key={index} className="flex items-center">
                  <img src={ListIcon} alt="List Icon" className="w-5 h-5 mr-2" />
                  <span dangerouslySetInnerHTML={{ __html: item }} />
                </li>
              ))}
            </ul>
          </div>
          <div className="my-4 flex items-center justify-center border-gray-300 rounded-md overflow-hidden">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Enter your Job ID"
              className="p-2 border-none flex-shrink-0 w-1/3"
            />
            <button
              onClick={toggleRecording}
              className="text-[#07980D] p-2"
              title={isRecording ? 'Stop Recording' : 'Start Recording'}
            >
              <FontAwesomeIcon icon={faMicrophone} size="lg" />
            </button>
            <button
              onClick={handleVerify}
              className="bg-[#23479F] text-white py-2 px-4 font-semibold hover:bg-blue-600 rounded-md ml-2"
            >
              Verify
            </button>
            <button
              onClick={handleSubmit}
              className={`bg-[#23479F] text-white py-2 px-4 font-semibold hover:bg-blue-600 rounded-md ml-2 ${credentialsVerified ? '' : 'opacity-50 cursor-not-allowed'}`}
              disabled={!credentialsVerified}
            >
              Start
            </button>
          </div>
        </div>
      </div>
      <audio ref={audioRef} style={{ display: 'none' }} />
      <div className='relative w-full'>
        <button
          onClick={toggleMute}
          className="absolute left-20 bottom-1 bg-blue-500 rounded-full text-[#23479F] p-2 shadow-lg"
        >
          <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} size="lg" />
        </button>
      </div>
    </div>
  );
};

export default InterviewBot;