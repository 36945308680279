import './App.css';
import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Login from './components/login';
import SignUp from './components/signUp';
import OTPVerification from './components/otpVerification';
import ForgotPassword from './components/forgotPassword';
import ResetPassword from './components/resetPassword';
import { useCookies } from 'react-cookie';
import InterviewBot from './pages/InterviewBot';
import QuestionPage from './pages/QuestionAndAnswer';
import Summary from './pages/QandASummary'; // Ensure this matches the correct import path
import { QuestionProvider } from './context/QuestionContext';
import DiscussionForum from './pages/DiscussionForum';
import Chat from './pages/Chat';

const App = () => {
  const [cookies] = useCookies(['jwtToken']);
  const jwtToken = cookies['jwtToken'];
  const [jobid, setjobid] = useState('');


  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Function to set authentication status
  const setAuthenticationStatus = (status) => {
    setIsAuthenticated(status);
  };

  var setjobidfun = function (id) {
    setjobid(id)
  }

  // Check if the user is authenticated
  useEffect(() => {
    if (jwtToken) {
      setAuthenticationStatus(true);
    } else {
      setAuthenticationStatus(false);
    }
  }, [jwtToken, cookies]);

  return (
    <>
    <QuestionProvider>
      <Layout jobid={jobid} isAuthenticated={isAuthenticated}>
       
        <Routes>
          {!isAuthenticated ? (
            <>
              <Route
                path="/login"
                element={
                  <Login setAuthenticationStatus={setAuthenticationStatus} />
                }
              />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/otp-verification" element={<OTPVerification />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </>
          ) : null}

          {isAuthenticated ? (
            <>
              <Route path="/interview-bot" element={<InterviewBot setjobidfun={setjobidfun} />} />
              <Route path="/question-and-answer" element={<QuestionPage jobId={jobid}/>}  />
              <Route path="/summary" element={<Summary jobId={jobid}/>} />
              <Route path="/discussion-forum" element={<DiscussionForum />} />
              <Route path="/chat" element={<Chat setjobidfun={setjobidfun}/>} />
            </>
          ) : null}

          {isAuthenticated ? (
            <Route path="/*" element={<Navigate to="/interview-bot" />} />
          ) : null}
          {!isAuthenticated ? (
            <Route path="/*" element={<Navigate to="/login" />} />
          ) : null}
        </Routes>
      </Layout>
    </QuestionProvider>
    </>
  );
};

export default App;