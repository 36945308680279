import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeMute, faVolumeUp, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useCookies } from 'react-cookie';
import { medbotVoices } from "../components/Mapping";

const Chat = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("Hi");
    const ws = useRef(null);
    const botMessageRef = useRef(null);
    const audioRef = useRef(null);
    const [cookies] = useCookies(['voice', 'jobId', 'email']);
    const [isMuted, setIsMuted] = useState(false);
    const [showButton, setShowButton] = useState(false);

    const toggleMute = () => {
        const audio = audioRef.current;
        audio.muted = !audio.muted;
        setIsMuted(!isMuted);
    };

    useEffect(() => {
        handleSend();
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                handleSend();
            }
        };

        const inputElement = document.getElementById("chat-input");
        inputElement.addEventListener("keydown", handleKeyDown);

        return () => {
            inputElement.removeEventListener("keydown", handleKeyDown);
        };
    }, [input]);

    const speak = async (text) => {
        const audio = audioRef.current;
        let voice = cookies.voice || medbotVoices[0].value;
        const body = JSON.stringify({ voice: voice, text: text });

        try {
            const response = await fetch('https://dev.genai.krtrimaiq.ai:8000/text_to_speech', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const mediaSource = new MediaSource();
            audio.src = URL.createObjectURL(mediaSource);

            mediaSource.addEventListener('sourceopen', async () => {
                const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
                const reader = response.body.getReader();

                const pump = async () => {
                    try {
                        const { done, value } = await reader.read();
                        if (done) {
                            mediaSource.endOfStream();
                            audio.play();
                            audio.onended = () => {
                                setShowButton(true);
                            };
                            return;
                        }
                        sourceBuffer.addEventListener('updateend', () => {
                            if (!sourceBuffer.updating) {
                                pump();
                            }
                        });
                        sourceBuffer.appendBuffer(value);
                    } catch (error) {
                        console.error('Error reading stream:', error);
                    }
                };

                await pump();
            });

            audio.oncanplaythrough = () => {
                audio.play();
            };
        } catch (error) {
            console.error('Error fetching or playing audio:', error);
        }
    };

    const formatDateTime = (date) => {
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const day = days[date.getDay()];
        const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return `${day} ${time}`;
    };

    const handleSend = () => {

        if (input) {
            console.log('ws open', input.trim());

            // Open WebSocket connection
            if (!ws.current || ws.current.readyState !== WebSocket.OPEN) {
                ws.current = new WebSocket(
                    'wss://hiring-assistant.dev2.genai.krtrimaiq.ai/temporary-test-chat?message=' +
                        encodeURIComponent(input) +
                        '&sender=sam'
                );
            }

            // Create user message
            if (messages.length > 0) {
                const userMessage = {
                    text: input,
                    sender: "user",
                    timestamp: new Date(),
                };
                setMessages([...messages, userMessage]);
            }
            setInput("");

            // Send message to WebSocket server
            const messageData = {
                message: input,
                sender: "sam"
            };
            ws.current.onopen = () => {
                console.log('ws open');
                ws.current.send(JSON.stringify(messageData));
            };

            // Handle incoming messages
            ws.current.onmessage = (event) => {
                try {
                    const response = JSON.parse(event.data);
                    if (response && response.length > 0) {
                        const newText = response[0].text;
                        const timestamp = new Date();

                        if (botMessageRef.current === null) {
                            const newBotMessage = { text: newText, sender: 'bot', timestamp };
                            botMessageRef.current = newBotMessage;
                            setMessages((prevMessages) => [...prevMessages, newBotMessage]);
                        } else {
                            botMessageRef.current.text = newText;
                            setMessages((prevMessages) => {
                                const updatedMessages = [...prevMessages];
                                const lastIndex = updatedMessages.length - 1;
                                if (lastIndex >= 0 && updatedMessages[lastIndex].sender === 'bot') {
                                    updatedMessages[lastIndex] = {
                                        ...updatedMessages[lastIndex],
                                        text: newText,
                                    };
                                } else {
                                    updatedMessages.push({ text: newText, sender: 'bot', timestamp });
                                }
                                return updatedMessages;
                            });
                        }
                    }
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            };

            ws.current.onclose = () => {
                console.log('WebSocket connection closed');
                const stripHtml = (html) => {
                    const tmp = document.createElement("DIV");
                    tmp.innerHTML = html;
                    return tmp.textContent || tmp.innerText || "";
                };
                
                const plainText = stripHtml(botMessageRef.current.text);
                if (botMessageRef.current) {
                    speak(plainText);
                }
                botMessageRef.current = null;
            };

            ws.current.onerror = (error) => {
                console.error('WebSocket error:', error);
            };
        }
    };

    return (
        <div className="flex flex-col">
            <div className="flex-grow p-4">
                <div className="text-[#224B99] text-center font-semibold text-3xl mb-4">
                    Interview Completed
                </div>
                <div className="max-w-2xl mx-auto bg-white shadow-lg rounded-lg p-4">
                    <div className="h-80 overflow-y-auto mb-4">
                        {messages.map((msg, index) => (
                            <div key={index} className={`mb-2 ${msg.sender === "bot" ? "text-left" : "text-right"}`}>
                            <div className={`inline-block max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg p-2 rounded-lg ${msg.sender === "bot" ? "bg-[#F7F9FD] text-[#224B99]" : "bg-[#F5F5F5]"} break-words`}>
                                <div className="text-xs text-[#646464] mt-1">
                                    {msg.sender === "bot" ? "KRTRI" : "YOU"} • {formatDateTime(new Date(msg.timestamp))}
                                </div>
                                <div className="break-words" dangerouslySetInnerHTML={{ __html: msg.text }}></div>
                            </div>
                        </div>
                        
                        ))}
                    </div>
                    <div className="flex">
                        <input
                            id="chat-input"
                            className="flex-grow p-2 border rounded-l-lg focus:outline-none"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Type your message..."
                        />
                        <button
                            className="w-20 bg-[#4366A8] text-white p-2 rounded-r-lg flex items-center justify-center"
                            onClick={handleSend}
                        >
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    </div>
                </div>
            </div>
            <audio ref={audioRef} style={{ display: 'none' }} />
      <div className='relative w-full'>
        <button
          onClick={toggleMute}
          className="absolute left-20 bottom-1 bg-blue-500 rounded-full text-[#23479F] p-2 shadow-lg"
        >
          <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} size="lg" />
        </button>
      </div>
        </div>
    );
};

export default Chat;
